import React from 'react'
import { Typo, TypoWithIcon } from './style'
import eyeIcon from '../../images/eye.svg'

const baseURL = process.env.REACT_APP_API_URL

interface FileProps {
  document: string
}

export const FileColumn = ({ document }: FileProps): JSX.Element => {
  if (document) {
    const fileUrl = `${baseURL}/Containers/witsawa-classwin/download/${document}`
    return (
      <a href={fileUrl} target="_blank" rel="noopener noreferrer">
        <TypoWithIcon>
          <img src={eyeIcon} width={16} height={16} />
          <Typo color="#5a69fe">View</Typo>
        </TypoWithIcon>
      </a>
    )
  }

  return <Typo>-</Typo>
}
