import styled from '@emotion/styled'
import { Typography } from 'antd'

const { Text } = Typography

type TypoProps = {
  color?: string
}

export const Typo = styled(Text)<TypoProps>`
  font-size: 12px;
  color: ${(props) => props.color};
`

export const TypoWithIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`

export const Action = styled.div`
  line-height: 0px;
  font-size: 30px;
  height: 100%;
  width: 100%;
  padding-bottom: 20px;
  cursor: pointer;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

export const Content = styled.div`
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
`
