/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import dayjs from 'dayjs'
import { Typo } from './style'

export const columns = [
  {
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'date',
    width: 150,
    render: (value: Date): JSX.Element => (
      <Typo>{dayjs(value).format('DD MMM YYYY HH:mm')}</Typo>
    ),
  },
  {
    title: 'Transaction ID',
    dataIndex: 'transactionId',
    key: 'transactionId',
    width: 150,
    render: (value: string): JSX.Element => <Typo>{value}</Typo>,
  },
  {
    title: 'Course',
    key: 'courseName',
    width: 200,
    render: (row: any): JSX.Element => <Typo>{row.metaData.course.name}</Typo>,
  },
  {
    title: 'Payer Name',
    key: 'paymentName',
    width: 200,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    render: (row: any): JSX.Element => {
      const studentName = `${row.metaData?.student?.firstname || ''} ${
        row.metaData?.student?.lastname || ''
      }`
      return (
        <Typo>{row.metaData?.payment?.data?.card?.name || studentName}</Typo>
      )
    },
  },
  {
    title: 'Course Price',
    dataIndex: 'totalPrice',
    key: 'totalPrice',
    width: 120,
    render: (value: number): JSX.Element => (
      <Typo>{value.toLocaleString()}</Typo>
    ),
  },
  {
    title: 'Income',
    dataIndex: 'income',
    key: 'income',
    width: 100,
    render: (value: number): JSX.Element => (
      <Typo strong color="#4cd64c">
        {value.toLocaleString()}
      </Typo>
    ),
  },
  {
    title: 'Fee',
    dataIndex: 'fee',
    key: 'fee',
    width: 120,
    render: (value: number): JSX.Element => (
      <Typo color="#cc0000">{value.toLocaleString()}</Typo>
    ),
  },
  {
    title: 'Status',
    key: 'status',
    width: 100,
    render: (row: any): JSX.Element => (
      <Typo>{row.metaData.payment.status}</Typo>
    ),
  },
]
