/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react'
import { PageHeader, Table, Input } from 'antd'
import dayjs from 'dayjs'
import request from '../../utils/request'
import { FileColumn } from './components'
import { Typo } from './style'

const { Search } = Input

const pageSize = 10

const CoursePaymentsPage = (): JSX.Element => {
  const [current, setCurrent] = useState(1)
  const [total, setTotal] = useState(0)
  const [data, setData] = useState<any>([])
  const [searchedWord, setSearchedWord] = useState('')

  useEffect(() => {
    let where: any = { amount: { gt: 0 } }
    if (searchedWord) {
      where = {
        amount: { gt: 0 },
        or: [
          {
            documentNo: {
              like: searchedWord,
              options: 'i',
            },
          },
          {
            'billingAddress.name': {
              like: searchedWord,
              options: 'i',
            },
          },
        ],
      }
    }
    const config = {
      params: {
        filter: {
          where,
          include: ['course', 'appUser'],
          order: 'createdAt DESC',
          limit: pageSize,
          skip: (current - 1) * pageSize,
        },
      },
    }
    const countConfig = {
      params: {
        where,
      },
    }
    const getData = async () => {
      try {
        const res = await request.get('/CoursePayments', config)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const count: any = await request.get(
          '/CoursePayments/count',
          countConfig
        )
        setData(res || [])
        setTotal(count.count || 0)
      } catch (error) {
        console.log(error)
      }
    }
    getData()
  }, [current, searchedWord])

  const pagination = {
    current,
    pageSize,
    total,
    onChange: (page: number) => setCurrent(page),
    showSizeChanger: false,
  }

  const columns = [
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'date',
      width: 140,
      render: (value: Date): JSX.Element => (
        <Typo>{dayjs(value).format('DD MMM YYYY HH:mm')}</Typo>
      ),
    },
    {
      title: 'Document No.',
      dataIndex: 'documentNo',
      key: 'documentNo',
      width: 130,
      render: (value: string): JSX.Element => (
        <Typo strong>{value || '-'}</Typo>
      ),
    },
    {
      title: 'Course Name',
      key: 'courseName',
      width: 200,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (row: any): JSX.Element => <Typo>{row.course?.name || ''}</Typo>,
    },
    {
      title: 'User',
      key: 'user',
      width: 180,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (row: any): JSX.Element => (
        <Typo>{`${row.appUser?.firstname || ''} ${
          row.appUser?.lastname || ''
        }`}</Typo>
      ),
    },
    {
      title: 'Provider',
      dataIndex: 'provider',
      key: 'provider',
      width: 110,
      render: (value: string): JSX.Element => <Typo>{value}</Typo>,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      width: 100,
      render: (value: number): JSX.Element => (
        <Typo>{value.toLocaleString()}</Typo>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (value: string): JSX.Element => <Typo>{value}</Typo>,
    },
    {
      title: 'Payer',
      key: 'company',
      width: 100,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (row: any): JSX.Element => (
        <Typo>{row.billingAddress?.name || '-'}</Typo>
      ),
    },
    {
      title: 'Billing Address',
      key: 'billingAddress',
      width: 220,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (row: any): JSX.Element => (
        <Typo>{row.billingAddress?.address || '-'}</Typo>
      ),
    },
    {
      title: 'Delivery Address',
      key: 'deliveryAddress',
      width: 220,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (row: any): JSX.Element => (
        <Typo>{row.billingAddress?.deliveryAddress || '-'}</Typo>
      ),
    },
    {
      title: 'Receipt',
      dataIndex: 'receipt',
      key: 'receipt',
      width: 100,
      render: (value: string): JSX.Element => <FileColumn document={value} />,
    },
    {
      title: 'Receipt (Copy)',
      dataIndex: 'copyReceipt',
      key: 'copyReceipt',
      width: 100,
      render: (value: string): JSX.Element => <FileColumn document={value} />,
    },
    // {
    //   key: 'action',
    //   width: 60,
    //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //   render: (row: any): JSX.Element => (
    //     <ActionButton coursePaymentId={row.id} />
    //   ),
    // },
  ]

  const onSearch = (value: string) => {
    setSearchedWord(value)
  }

  return (
    <PageHeader title="Course Payments">
      <div style={{ paddingBottom: 20 }}>
        <Search
          placeholder="search by Document No. or Payer"
          allowClear
          onSearch={onSearch}
          style={{ width: 300 }}
        />
      </div>
      <Table
        columns={columns}
        dataSource={data}
        rowKey="id"
        pagination={pagination}
        tableLayout="fixed"
        scroll={{ x: '100%' }}
        sticky={true}
      />
    </PageHeader>
  )
}

export default CoursePaymentsPage
