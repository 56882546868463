import React, { useState, useEffect } from 'react'
import { PageHeader, Table } from 'antd'
import request from '../../utils/request'
import { Status, UploadColumn, ActionButton } from './components'
import { Typo } from './style'

const pageSize = 10

const TransferHistoryPage = (): JSX.Element => {
  const [current, setCurrent] = useState(1)
  const [total, setTotal] = useState(0)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [data, setData] = useState<any>([])
  const config = {
    params: {
      filter: {
        order: 'updatedAt DESC',
        limit: pageSize,
        skip: (current - 1) * pageSize,
      },
    },
  }

  const getData = async () => {
    try {
      const res = await request.get('/SchoolTransfers', config)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const count: any = await request.get('/SchoolTransfers/count')
      // console.log('res', res)
      setData(res || [])
      setTotal(count.count || 0)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getData()
  }, [current])

  const pagination = {
    current,
    pageSize,
    total,
    onChange: (page: number) => setCurrent(page),
    showSizeChanger: false,
  }

  const columns = [
    {
      title: 'School Name',
      dataIndex: 'schoolName',
      key: 'schoolName',
      width: 200,
      ellipsis: true,
      render: (value: string): JSX.Element => <Typo>{value}</Typo>,
    },
    {
      title: 'Phone No.',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: 140,
      render: (value: string): JSX.Element => <Typo>{value}</Typo>,
    },
    {
      title: 'Account Name',
      dataIndex: 'accountName',
      key: 'accountName',
      width: 200,
      ellipsis: true,
      render: (value: string): JSX.Element => <Typo>{value}</Typo>,
    },
    {
      title: 'Account No.',
      key: 'accountNumber',
      width: 120,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (row: any): JSX.Element => (
        <Typo>{`${row.bankBrand} ${row.accountNumber}`}</Typo>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      width: 100,
      render: (value: number): JSX.Element => (
        <Typo>{value.toLocaleString()}</Typo>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (value: string): JSX.Element => <Status value={value} />,
    },
    {
      title: 'Payment',
      key: 'payment',
      width: 110,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (row: any): JSX.Element => (
        <UploadColumn
          id={row.id}
          document={row.transferSlip}
          fieldName="transferSlip"
          fetch={getData}
        />
      ),
    },
    {
      title: 'Receipt',
      key: 'receipt',
      width: 110,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (row: any): JSX.Element => (
        <UploadColumn
          id={row.id}
          document={row.receipt}
          fieldName="receipt"
          fetch={getData}
        />
      ),
    },
    {
      key: 'action',
      width: 60,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (row: any): JSX.Element => (
        <ActionButton schoolId={row.schoolId} transferId={row.id} />
      ),
    },
  ]

  return (
    <PageHeader title="Transfer History">
      <Table
        columns={columns}
        dataSource={data}
        rowKey="id"
        pagination={pagination}
        tableLayout="fixed"
        scroll={{ x: '100%' }}
        sticky={true}
      />
    </PageHeader>
  )
}

export default TransferHistoryPage
