import React from 'react'
import { useHistory } from 'react-router-dom'
import { Upload, Popover, message } from 'antd'
import request from '../../utils/request'
import { Typo, TypoWithIcon, Action, ContentWrapper, Content } from './style'
import eyeIcon from '../../images/eye.svg'
import uploadIcon from '../../images/upload.svg'

const baseURL = process.env.REACT_APP_API_URL

interface StatusProps {
  value: string
}

export const Status = ({ value }: StatusProps): JSX.Element => {
  switch (value) {
    case 'awaiting':
      return (
        <Typo strong color="#ff8c00">
          Awaiting
        </Typo>
      )
    case 'completed':
      return (
        <Typo strong color="#2abb2e">
          Completed
        </Typo>
      )
    default:
      return <Typo>{value || ''}</Typo>
  }
}

interface UploadProps {
  id: string
  document: string
  fieldName: string
  fetch?: () => void
}

export const UploadColumn = ({
  id,
  document,
  fieldName,
  fetch,
}: UploadProps): JSX.Element => {
  if (document) {
    const fileUrl = `${baseURL}/Containers/witsawa-classwin/download/${document}`
    return (
      <a href={fileUrl} target="_blank" rel="noopener noreferrer">
        <TypoWithIcon>
          <img src={eyeIcon} width={16} height={16} />
          <Typo color="#5a69fe">Uploaded</Typo>
        </TypoWithIcon>
      </a>
    )
  }

  const uploadUrl = `${baseURL}/Containers/witsawa-classwin/upload`
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = async (info: any) => {
    // console.log('info', info)
    if (info.file.status === 'done') {
      const responseFile: string = info.file.response.result.files.file[0].name
      // console.log('uploadedFile', uploadedFile)
      const data = { [fieldName]: responseFile }
      try {
        await request.patch(`/SchoolTransfers/${id}`, data)
        message.success('Uploaded successfully')
        fetch?.()
      } catch (error) {
        message.error('Upload failed')
        console.log(error)
      }
    } else if (info.file.status === 'error') {
      message.error('Upload failed')
    }
  }

  return (
    <Upload
      method="post"
      action={uploadUrl}
      showUploadList={false}
      onChange={onChange}
    >
      <TypoWithIcon>
        <img
          src={uploadIcon}
          width={14}
          height={14}
          style={{ marginRight: 2 }}
        />
        <Typo color="#5a69fe">Upload</Typo>
      </TypoWithIcon>
    </Upload>
  )
}

interface ActionProps {
  schoolId: string
  transferId: string
}

const PopContent = ({ schoolId, transferId }: ActionProps): JSX.Element => {
  const history = useHistory()
  const viewDetailUrl = `/transfer-history/${transferId}`

  return (
    <ContentWrapper>
      {/* TODO: add edit action */}
      <Content
        onClick={() => history.push(viewDetailUrl, { schoolId, transferId })}
      >
        View
      </Content>
    </ContentWrapper>
  )
}

export const ActionButton = (props: ActionProps): JSX.Element => {
  return (
    <Popover content={<PopContent {...props} />} trigger="click">
      <Action>...</Action>
    </Popover>
  )
}
