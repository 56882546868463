import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { PageHeader, Table } from 'antd'
import request from '../../utils/request'
import { columns } from './columns'

const pageSize = 20

const TransferDetailPage = (): JSX.Element => {
  const history = useHistory()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { schoolId, transferId }: any = history.location.state
  const dataUrl = `/Schools/${schoolId}/schoolTransfers/${transferId}/detail`
  const [current, setCurrent] = useState(1)
  const [total, setTotal] = useState(0)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [data, setData] = useState<any>([])
  const config = {
    params: {
      filter: {
        order: 'createdAt DESC',
        limit: pageSize,
        skip: (current - 1) * pageSize,
      },
    },
  }

  const getData = async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const res: any = await request.get(dataUrl, config)
      // console.log('res.data', res.data)
      setData(res.data || [])
      setTotal(res.count || 0)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getData()
  }, [current])

  const onBack = () => {
    history.goBack()
  }

  const pagination = {
    current,
    pageSize,
    total,
    onChange: (page: number) => setCurrent(page),
    showSizeChanger: false,
  }

  return (
    <PageHeader title="Transfer Detail" onBack={onBack}>
      <Table
        columns={columns}
        dataSource={data}
        rowKey="id"
        pagination={pagination}
        tableLayout="fixed"
        scroll={{ x: '100%' }}
        sticky={true}
      />
    </PageHeader>
  )
}

export default TransferDetailPage
