import React, { useEffect } from 'react'
import { Form, Input, Button, message } from 'antd'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'

type FormValues = {
  email: string
  password: string
}

const LoginPage = (): JSX.Element => {
  const history = useHistory()
  const {
    authContext: { login },
    state: { user },
  } = useAuth()

  useEffect(() => {
    if (user?.id) {
      history.push('/dashboard')
    }
  }, [user])

  const onFinish = async (values: FormValues) => {
    try {
      console.log('Success:', values)
      await login(values)
      message.success('Login Success')
    } catch (e) {
      message.error('Login Failed')
    }
  }

  return (
    <Form name="login" layout="vertical" initialValues={{}} onFinish={onFinish}>
      <Form.Item
        label="Email"
        name="email"
        rules={[{ required: true, message: 'Please input your email!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Login
        </Button>
      </Form.Item>
    </Form>
  )
}

export default LoginPage
