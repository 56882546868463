import styled from '@emotion/styled'
import { Typography } from 'antd'

const { Text } = Typography

type TypoProps = {
  color?: string
}

export const Typo = styled(Text)<TypoProps>`
  font-size: 12px;
  color: ${(props) => props.color};
`

export const TypoWithIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`
